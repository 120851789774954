<template>
  <div>
    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ sidebarTitle }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideSideBar()"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="mt-1">
          <b-col md="12">
            <b-form-group
              label="Parent Head"
              invalid-feedback="Parent head is required."
              ref="parent"
            >
              <v-select
                ref="parent"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                :options="parentOptions"
                :reduce="(opt) => opt.id"
                label="title"
                v-model="myObj.parentHead"
                placeholder="Select parent"
              />
              <!-- @input="setNature()" -->
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Code"
              invalid-feedback="code is required."
              ref="code"
            >
              <b-form-input
                id="mc-first-name"
                placeholder="Enter code here"
                v-validate="'required'"
                ref="code"
                v-model="myObj.code"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Account"
              invalid-feedback="Account is required."
              ref="name"
            >
              <b-form-input
                id="mc-first-name"
                placeholder="Enter account name here"
                v-validate="'required'"
                ref="name"
                v-model="myObj.title"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Nature"
              invalid-feedback="Nature is required."
              ref="nature"
            >
              <v-select
                ref="nature"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                :options="typeOptions"
                :reduce="(opt) => opt.name"
                label="name"
                v-model="myObj.nature"
                placeholder="Select nature"
              />
              <!-- :disabled="myObj.parentHead > 0" -->
            </b-form-group>
          </b-col>
          <b-col
            md="12"
            v-if="myObj.nature == 'Asset' || myObj.nature == 'Liability'"
          >
            <b-form-group
              label="Type"
              invalid-feedback="Type is required."
              ref="period"
            >
              <v-select
                ref="period"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                :options="periodOptions"
                :reduce="(opt) => opt.text"
                label="text"
                v-model="myObj.period"
                placeholder="Select type"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="12"
            v-if="myObj.nature == 'Asset' && myObj.period == 'Non-Current'"
          >
            <b-form-group
              label="Depreciation %"
              invalid-feedback="Depreciation % is required."
              ref="depr"
            >
              <b-form-input
                ref="depr"
                type="number"
                placeholder="Enter percentage"
                v-model="myObj.depreciationPerc"
                @input="checkDep()"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Category"
              invalid-feedback="Category is required."
              ref="cat"
            >
              <v-select
                ref="cat"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                :options="categoryOptions"
                :reduce="(opt) => opt.value"
                label="text"
                v-model="myObj.cashFlowCategory"
                placeholder="Select category"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Opening Balance"
              invalid-feedback="Opening Balance is required."
              ref="balance"
            >
              <b-form-input
                id="mc-first-name"
                type="number"
                placeholder="Enter opening balance"
                ref="balance"
                v-model="myObj.openingBalance"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              class="mr-1 fullWidth"
              @click="Add()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-if="request == false"> {{ sidebarButton }} </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility2"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Settings</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility2 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="mt-1">
          <b-col md="12">
            <b-form-group
              label="Opening Balance"
              invalid-feedback="Opening Balance is required."
              ref="bal"
            >
              <b-form-input
                placeholder="Enter balance here"
                ref="bal"
                v-model="settObj.valueNumber"
              />
            </b-form-group>
          </b-col>

          <b-col md="12" class="mt-1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class=""
              @click="saveSetting()"
              :disabled="saving"
              block
            >
              <b-spinner v-if="saving" small type="grow" />
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-card>
      <b-row class="mt-1">
        <b-col xl="2" lg="3" md="4" sm="12" cols="12" v-if="rights.add">
          <b-button
            @click="AddOpen()"
            style="width: 100%"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">Add Account</span>
          </b-button>
        </b-col>

        <b-col
          class="mt-lg-0 mt-md-0 mt-sm-1 mt-1 d-flex align-items-start"
          xl="10"
          lg="9"
          md="8"
          sm="12"
          cols="12"
        >
          <b-form-group class="w-100">
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>

              <b-form-input v-model="searchQuery" placeholder="Search...">
              </b-form-input>
            </b-input-group>
          </b-form-group>
          <b-button
            variant="outline-primary"
            class="btn-icon ml-1"
            v-b-tooltip.hover.top
            title="Set Opening Balance"
            @click="loadSettings()"
          >
            <feather-icon icon="SettingsIcon" />
          </b-button>
        </b-col>
      </b-row>

      <b-table
        class="mt-1"
        ref="selectableTable"
        :items="filters"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :busy="dataLoading"
        responsive
        show-empty
        hover
        :selectable="rights.edit"
        select-mode="single"
        @row-selected="Edit($event[0])"
      >
        <template #table-busy>
          <div
            class="d-flex justify-content-center mb-3"
            style="margin-top: 50px"
          >
            <b-spinner
              style="width: 3rem; height: 3rem"
              type="grow"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </div>
        </template>
        <template #cell(title)="data">
          <b-badge style="margin-inline: 2px" variant="light-primary">
            {{ data.item.title }}
          </b-badge>
        </template>
        <template #cell(meta_parentHead)="data">
          <b-badge style="margin-inline: 2px" variant="light-primary">
            {{ data.item.meta_parentHead }}
          </b-badge>
        </template>
        <template #cell(openingBalance)="data">
          <b-badge style="margin-inline: 2px" variant="light-primary">
            {{ data.item.openingBalance }}
          </b-badge>
        </template>
        <template #cell(code)="data">
          <b-badge style="margin-inline: 2px" variant="light-primary">
            {{ data.item.code }}
          </b-badge>
        </template>

        <template #head(actions)="data">
          <div class="text-center">
            <span>{{ data.label }}</span>
          </div>
        </template>

        <template #cell(actions)="data">
          <div class="text-center">
            <b-button
              v-if="rights.edit"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-b-tooltip.hover.left
              title="Edit"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="Edit(data.item)"
            >
              <feather-icon icon="EditIcon" class="" />
            </b-button>

            <b-button
              v-if="rights.delete"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-danger"
              v-b-tooltip.hover.right
              title="Delete"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="Delete(data.item.id)"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </div>
        </template>
      </b-table>
      <b-col cols="12" class="d-flex align-items-center justify-content-center">
        <b-pagination
          v-model="currentPage"
          :total-rows="filters.length"
          :per-page="perPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-col>
    </b-card>
  </div>
</template>

<script>
import { required, minLength, between } from "vuelidate/lib/validators";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BInputGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import store from "@/store";
import { avatarText } from "@core/utils/filter";
import flatPickr from "vue-flatpickr-component";
import { BFormSelect } from "bootstrap-vue";
//import Multiselect from "vue-multiselect";
import VueGallery from "vue-gallery";
import { forEach } from "postcss-rtl/lib/affected-props";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    //Multiselect,

    BCard,
    BRow,
    BBadge,
    BTable,
    BCol,
    BFormInput,
    BButton,
    BSidebar,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    // BFormCheckbox,
    BForm,
    BPagination,
    BSpinner,

    BDropdown,
    BDropdownItem,

    vSelect,
  },
  props: {},
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  async created() {
    let right = {};
    this.$store.state.menu.forEach((el) => {
      el.children.forEach((ch) => {
        right[ch.route] = {
          view: ch.view,
          add: ch.add,
          edit: ch.edit,
          delete: ch.delete,
        };
      });
    });
    // console.log(right);
    this.$store.commit("setRights", right);

    if (!this.$store.state.rights[this.$route.name]) {
      // console.log(this.$store.state.rights[this.$route.name]);
      this.$router.replace({
        name: "misc-not-authorized",
      });
    } else {
      this.rights = this.$store.state.rights[this.$route.name];
      this.LoadData();
    }
  },
  computed: {
    ...mapGetters({ cmbDepartment: "cmbDepartment" }),
    ...mapGetters({ data: "data" }),
    getColor(ind) {
      console.log("%%%%%% getting color", ind.index);
      return this.color[ind];
    },

    PageChange() {
      console.log(this.currentPage);
      return this.LoadData();
    },
    filters: function () {
      return this.items.filter((pro) => {
        return pro.title.toLowerCase().match(this.searchQuery.toLowerCase());
      });
    },
  },
  data() {
    return {
      rights: {},
      myDepartments: [],
      request: false,
      colorDep: [],
      visibility1: false,
      color: [
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
      ],

      sidebarTitle: "Add Account",
      sidebarButton: "Save",
      buttonIcon: "PlusIcon",
      dataLoading: false,

      visibility: false,
      logoloading: "",
      searchQuery: "",
      currentPage: 1,
      perPage: 30,
      totalRows: 0,

      errors: {
        status: false,
      },

      index: null,

      fields: [
        { label: "account", key: "title" },
        { label: "parent", key: "meta_parentHead" },
        { label: "opening balance", key: "openingBalance" },
        { label: "code", key: "code" },
        { key: "actions", label: "actions" },
      ],
      items: [
        // {
        //   id: 0,
        //   parent: 999,
        //   account: "asa",
        //   code: 101,
        //   campusID: this.$store.state.userData.cId,
        // },
      ],
      parentOptions: [],
      typeOptions: [
        { name: "Asset", value: "asset" },
        { name: "Equity", value: "equity" },
        { name: "Expense", value: "expense" },
        { name: "Liability", value: "liability" },
        { name: "Revenue", value: "revenue" },
      ],
      categoryOptions: [
        { text: "None", value: "0" },
        { text: "Operations Activities", value: "1" },
        { text: "Investing Activities", value: "2" },
        { text: "Financing Activities", value: "3" },
      ],
      periodOptions: [
        { text: "Current", value: "current" },
        { text: "Non-Current", value: "non-current" },
      ],
      selected: null,
      rangeDate: null,
      myObj: {
        id: 0,
        parent: 0,
        account: "",
        code: 0,
        campusID: this.$store.state.userData.cId,
      },
      depObj: {
        id: 0,
        name: "",
        campusID: this.$store.state.userData.cId,
      },
      detailObj: {},
      timeout: 10000,
      settObj: {
        id: 0,
        key: "opening-balance",
        value: "op",
        valueBool: true,
        valueNumber: 0,
        campusID: this.$store.state.userData.cId,
      },
      visibility2: false,
      saving: false,
    };
  },
  validations: {
    depObj: {
      name: {
        required,
        minLength: minLength(4),
      },
    },
    age: {
      between: between(20, 30),
    },
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    setNature() {
      if (this.myObj.parentHead > 0) {
        this.myObj.nature = this.parentOptions.find(
          (el) => el.id == this.myObj.parentHead
        ).nature;
        console.log(this.myObj.nature);
      }
    },

    async loadSettings() {
      var obj = {
        url:
          this.$store.state.domain +
          "Settings/LoadSettings?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        body: ["op"],
        context: this,
        subjects: true,
        token: this.$store.state.userData.token,
      };
      let res = await this.post(obj);
      this.settObj = res[0];
      console.log(this.settObj);

      this.visibility2 = true;
      var elem = this.$refs["bal"];
      elem.state = undefined;
    },

    checkVal() {
      var elem = this.$refs["bal"];
      if (isNaN(parseInt(this.settObj.valueNumber))) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async saveSetting() {
      if (this.checkVal() == true) {
        this.settObj.valueNumber = parseInt(this.settObj.valueNumber);
        this.saving = true;
        console.log(this.settObj);
        var status = await this.put({
          url:
            this.$store.state.domain +
            "Settings/SaveSettings?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: [this.settObj],
          message: "Settings saved successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.saving = false;
        if (status) {
          this.visibility2 = false;
        }
      }
    },

    async Edit(item) {
      //   var obj = {
      //     url:
      //       this.$store.state.domain +
      //       "examtypes/GetSelected?id=" +
      //       id +
      //       "&db=" +
      //       this.$store.state.userData.db,
      //     token: this.$store.state.userData.token,
      //   };
      //   this.myObj = await this.get(obj);
      //   console.log("editObj:", this.myObj);
      this.myObj = { ...item };
      // this.setNature();
      if (this.myObj.period == "current") this.myObj.period = "Current";
      else if (this.myObj.period == "non-current")
        this.myObj.period = "Non-Current";
      this.visibility = true;
      this.sidebarTitle = "Edit account";
      this.sidebarButton = "Update";
      var elem = this.$refs["name"];
      elem.state = undefined;
      var elem = this.$refs["code"];
      elem.state = undefined;
      var elem = this.$refs["parent"];
      elem.state = undefined;
      var elem = this.$refs["nature"];
      elem.state = undefined;
    },

    AddOpen() {
      this.myObj = {
        id: 0,
        title: "",
        code: "",
        parentHead: 0,
        nature: "",
        openingBalance: 0,
        cashFlowCategory: "0",
        period: "Current",
        depreciationPerc: 0,
        campusID: this.$store.state.userData.cId,
      };

      this.visibility = true;
      this.sidebarTitle = "Add account";
      this.sidebarButton = "Save";
      var elem = this.$refs["name"];
      elem.state = undefined;
      var elem = this.$refs["code"];
      elem.state = undefined;
      var elem = this.$refs["parent"];
      elem.state = undefined;
      var elem = this.$refs["nature"];
      elem.state = undefined;
    },

    CheckName() {
      var elem = this.$refs["name"];
      if (this.myObj.title == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckCode() {
      var elem = this.$refs["code"];
      if (this.myObj.code === "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckParent() {
      var elem = this.$refs["parent"];
      if (this.myObj.parentHead == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckType() {
      var elem = this.$refs["nature"];
      if (this.myObj.nature == "" || this.myObj.nature == null) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckPeriod() {
      if (this.myObj.nature == "Asset" || this.myObj.nature == "Liability") {
        var elem = this.$refs["period"];
        if (!this.myObj.period) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    checkDep() {
      if (this.myObj.nature == "Asset" && this.myObj.period == "Non-Current") {
        var elem = this.$refs["depr"];
        let x = parseFloat(this.myObj.depreciationPerc);
        if (isNaN(x) || x < 0 || x > 100) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },

    hideSideBar() {
      this.visibility = false;
    },

    async LoadData() {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "Accounts/LoadData?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.items = await this.get(obj);
      // console.log("acc", this.items);

      var obj2 = {
        url:
          this.$store.state.domain +
          "Accounts/LoadParents?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.parentOptions = await this.get(obj2);
      // console.log("par", this.parentOptions);

      this.parentOptions.unshift({
        id: 0,
        title: "None",
      });

      this.dataLoading = false;
    },

    async Add() {
      this.CheckName();
      this.CheckCode();
      this.CheckType();
      this.CheckPeriod();
      this.checkDep();
      // this.CheckParent();
      if (
        this.CheckName() == true &&
        this.CheckCode() == true &&
        this.CheckType() == true &&
        this.CheckPeriod() == true &&
        this.checkDep() == true
        // this.CheckParent() == true
      ) {
        this.myObj.openingBalance = parseInt(this.myObj.openingBalance);
        this.myObj.depreciationPerc = parseFloat(this.myObj.depreciationPerc);
        this.request = true;
        // console.log("obj:", this.myObj);
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Accounts/save?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: this.myObj,
          message: "Account added successfully.",
          context: this,
          token: this.$store.state.userData.token,
          showError: true,
        });
        this.request = false;
        if (status) {
          this.LoadData();
          this.visibility = false;
        }
      } else console.log("not");
    },

    async Delete(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });
      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "Accounts/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          message: "account removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadData();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
</style>
